'use client'

import { MouseEvent } from 'react'
import { Image, Badge } from '@vinted/web-ui'
import { useIntl } from 'react-intl'

import useAsset from 'hooks/useAsset'
import useToggleFavourite from 'hooks/useToggleFavourite'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useSession from 'hooks/useSession'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import { getItemStatusMessage, getProductItemStatus } from 'data/utils/item'
import { clickEvent, favouriteItemEvent } from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import ItemBox from 'components/ItemBox'
import ItemBoxOwner from 'components/ItemBox/ItemBoxOwner'

import { ProductItemModel } from 'types/models'
import { ContentSource } from 'constants/tracking/content-sources'
import { brazeLogCustomEvent } from 'libs/common/braze/utils/custom-event'
import { BrazeCustomEvent } from 'libs/common/braze/constants'
import { mapOriginToWindow } from 'libs/utils/url'
import { GoogleTagManagerEvent } from 'constants/google'
import { googleTagManagerTrack } from 'data/utils/google'

import useItemDescription from './hooks/useItemDescription'
import ItemPriceBreakdown from './ItemPriceBreakdown'

type Props = {
  item: ProductItemModel
  renderFooter?: JSX.Element | null
  showOwner?: boolean
  showStatus?: boolean
  showStatusAsBadge?: boolean
  showFavourite?: boolean
  viewingSelf?: boolean
  testId?: string
  url?: string
  isUrlDisabled?: boolean
  showPriceBreakdown?: boolean
  hideBusinessBadge?: boolean
  isBumpTextHidden?: boolean
  showPhotoCollage?: boolean
  isDescriptionHidden?: boolean
  imagesExperimentalOverlayDisabled?: boolean
  imageRatio?: ComponentProps<typeof Image>['ratio']
  onClick?: (event: MouseEvent) => void
  onFavouriteToggle?: (payload: {
    itemId: number
    itemContentSource?: ContentSource | null
    isFollowEvent: boolean
  }) => void
  informationCellCssClasses?: string
  collectionTitle?: string
}

const ProductItem = ({
  item,
  onClick,
  renderFooter,
  testId,
  url,
  showOwner = true,
  showStatus = false,
  showStatusAsBadge = false,
  showFavourite = true,
  viewingSelf = false,
  isUrlDisabled = false,
  showPriceBreakdown = true,
  onFavouriteToggle,
  hideBusinessBadge,
  showPhotoCollage,
  isDescriptionHidden,
  imagesExperimentalOverlayDisabled,
  imageRatio,
  informationCellCssClasses,
  collectionTitle,
  isBumpTextHidden = false,
}: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()
  const asset = useAsset('assets/no-photo')
  const { user: sessionUser } = useSession()
  const { locale } = useIntl()

  const portalMergeSourceEnabled = useFeatureSwitch('portal_merge_source')
  const isFavouriteButtonAriaLabelWithCount = useFeatureSwitch(
    'favourite_button_aria_label_with_count',
  )
  const isItemUrlOriginMapperEnabled = useFeatureSwitch('item_url_origin_mapper')

  const viewedByGod = sessionUser?.is_god ?? false
  const userExternalId = sessionUser?.external_id || null
  const viewedByModerator = sessionUser?.moderator ?? false

  const description = useItemDescription({ item, isDescriptionHidden })

  const isFavouriteEnabled = showFavourite && !viewingSelf && !portalMergeSourceEnabled
  const isPrivilegedUser = viewingSelf || viewedByGod || viewedByModerator

  const { isFavourite, favouriteCount, toggleFavourite, hasFavouritedChanged } = useToggleFavourite(
    {
      type: useToggleFavourite.Type.Item,
      entityId: item.id,
      isFavourite: item.isFavourite,
      count: item.favouriteCount,
    },
  )

  const getTranslatedStatusMessage = () => {
    if (collectionTitle) {
      return {
        content: translate('item.status.in_collection', { name: collectionTitle }),
        theme: 'amplified' as const,
      }
    }

    const status = showStatus ? getProductItemStatus(item, isPrivilegedUser) : null

    const statusMessage = getItemStatusMessage(status)

    if (!statusMessage) return null

    return {
      content: translate(String(statusMessage.content)),
      theme: statusMessage.theme,
    }
  }

  const getBadge = (): ComponentProps<typeof Badge> | null => {
    if (showStatusAsBadge) {
      const status = getTranslatedStatusMessage()

      if (status) {
        return status
      }
    }

    if (!item.badge) return null

    const { theme, body } = item.badge

    return { theme, content: body }
  }

  function getSecondaryBadge() {
    if (!item.user.isBusiness || hideBusinessBadge) return undefined

    return {
      content: translate('business.badge'),
    }
  }

  const handleFavouriteToggleSuccess = (newIsFavourite: boolean) => {
    if (!newIsFavourite) return

    googleTagManagerTrack(GoogleTagManagerEvent.AddToFavourites, {
      item_id: item.id,
    })

    brazeLogCustomEvent({
      event: BrazeCustomEvent.AddedToFavourites,
      modelId: item.id,
      userExternalId,
    })
  }

  const handleFavouriteToggleClick = () => {
    if (onFavouriteToggle) {
      onFavouriteToggle({
        itemId: item.id,
        itemContentSource: item.contentSource,
        isFollowEvent: !isFavourite,
      })
    } else {
      track(clickEvent({ target: ClickableElement.Favourite }))
      track(favouriteItemEvent({ itemId: item.id, isFollowEvent: !isFavourite }))
    }

    toggleFavourite({
      onSuccess: handleFavouriteToggleSuccess,
    })
  }

  const getStatusProps = (): ComponentProps<typeof ItemBox>['status'] | null => {
    if (showStatusAsBadge) return null

    return getTranslatedStatusMessage()
  }

  function getFavouriteProps() {
    if (!isFavouriteEnabled) return undefined

    const textWithoutCount = translate(
      isFavourite ? 'item.a11y.favorited' : 'item.a11y.not_favorited',
    )
    const textWithCount = translate(
      isFavourite ? 'item.a11y.unfavourite' : 'item.a11y.favourite',
      { count: favouriteCount },
      { count: favouriteCount },
    )

    return {
      favourited: isFavourite,
      count: favouriteCount,
      ariaIcon: {
        'aria-label': isFavouriteButtonAriaLabelWithCount ? textWithCount : textWithoutCount,
      },
      onClick: handleFavouriteToggleClick,
    }
  }

  const getAltTitle = (title: string, price: string) => {
    return translate('item.a11y.url_title', {
      title,
      price,
      brand: item.brandTitle || translate('item.a11y.no_brand'),
      size: item.sizeTitle || translate('item.a11y.no_size'),
    })
  }

  const getBumpText = () => {
    const isPromotedStatusHidden = !getStatusProps() && item.isPromoted

    if (!isPromotedStatusHidden) return null

    if (viewingSelf) return null

    return <span data-testid="bump-badge">{translate('item.status.bumped')}</span>
  }

  const {
    id,
    title,
    priceWithDiscount,
    price,
    thumbnailUrl,
    thumbnailUrls,
    dominantColor,
    user,
    iconBadges,
  } = item

  let itemUrl = url || item.url
  itemUrl = isItemUrlOriginMapperEnabled ? mapOriginToWindow(itemUrl) : itemUrl

  let itemOwnerProps: ComponentProps<typeof ItemBoxOwner> | null = null
  const userThumbnailUrl = user.thumbnailUrl || asset(EMPTY_USER_IMAGE_NAME)

  if (showOwner && user.login && user.profileUrl && user.id) {
    const profileUrl = isItemUrlOriginMapperEnabled
      ? mapOriginToWindow(user.profileUrl)
      : user.profileUrl

    itemOwnerProps = {
      id: user.id,
      name: user.login,
      image: userThumbnailUrl,
      url: profileUrl,
      alt: '',
    }
  }

  const currentPrice = formatCurrencyAmount(priceWithDiscount || price, locale)
  const oldPrice = priceWithDiscount ? formatCurrencyAmount(price, locale) : null

  const altTitle = getAltTitle(title, currentPrice)

  const itemTestId = testId || `product-item-id-${id}`

  return (
    <ItemBox
      url={isUrlDisabled ? undefined : itemUrl}
      alt={altTitle}
      price={currentPrice}
      oldPrice={oldPrice}
      image={thumbnailUrl}
      imageColor={dominantColor}
      imagesExperimental={showPhotoCollage ? thumbnailUrls : undefined}
      onClick={onClick}
      owner={itemOwnerProps}
      favourite={getFavouriteProps()}
      hasFavouritedChanged={hasFavouritedChanged}
      description={description}
      renderFooter={renderFooter}
      status={getStatusProps()}
      badge={getBadge()}
      secondaryBadgeExperimental={getSecondaryBadge()}
      bumpText={!isBumpTextHidden && getBumpText()}
      testId={itemTestId}
      renderPriceBreakdown={
        showPriceBreakdown && (
          <ItemPriceBreakdown
            testId={itemTestId}
            serviceFee={item.serviceFee}
            id={item.id}
            price={item.price}
            totalItemPrice={item.totalItemPrice}
            user={item.user}
            priceWithDiscount={item.priceWithDiscount}
          />
        )
      }
      imagesExperimentalOverlayDisabled={imagesExperimentalOverlayDisabled}
      imageRatio={imageRatio}
      informationCellCssClasses={informationCellCssClasses}
      iconBadges={iconBadges}
    />
  )
}

export default ProductItem
