import { AbTestExposeEventExtra } from 'libs/common/event-tracker/types'
import { ProductItemModel } from 'types/models'

type Props = {
  item: ProductItemModel
  isDescriptionHidden?: boolean
}

type ItemDescription = { title: string; subtitle: string; exposure?: AbTestExposeEventExtra } | null

const useItemDescription = (props: Props): ItemDescription => {
  const { item, isDescriptionHidden } = props

  if (isDescriptionHidden) return null

  return {
    title: item.itemBox?.firstLine || '',
    subtitle: item.itemBox?.secondLine || '',
    exposure: item.itemBox?.exposure,
  }
}

export default useItemDescription
